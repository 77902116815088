<template>
  <v-card flat class="service_card_container">
    <v-col cols="12">
      <md-content>
        <div id="collectionDetails">
          <div class="md-subheading mb-5 subheading_color py-3 white--text">
            Collection Details
          </div>
          <v-row>
            <v-col class="form-label">
              <label>Collection Number:</label>
            </v-col>
            <v-col cols="6" class="form-value">
              <label class="font-weight-bold">{{
                collectionData.collection_number
              }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="form-label">
              <label>Name:</label>
            </v-col>
            <v-col cols="6" class="form-value">
              <label class="font-weight-bold">
                {{ collectionData.name }}
              </label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="form-label">
              <label>Brand:</label>
            </v-col>
            <v-col cols="6" class="form-value">
              <label
                class="font-weight-bold brand-listing"
                @click="
                  openCompanyProfile(collectionData.brand_organization.uuid)
                "
                >{{ collectionData.brand_organization.name }}</label
              >
            </v-col>
          </v-row>

          <v-row>
            <v-col class="form-label">
              <label>Created at:</label>
            </v-col>
            <v-col cols="6" class="form-value">
              <label class="font-weight-bold">{{
                formatDate(collectionData.created_at)
              }}</label>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="form-label">
              <label>Updated at:</label>
            </v-col>
            <v-col cols="6" class="form-value">
              <label class="font-weight-bold">{{
                formatDate(collectionData.updated_at)
              }}</label>
            </v-col>
          </v-row>

          <div>
            <div class="md-subheading my-5 subheading_color py-3 white--text">
              Added Suppliers
            </div>
            <div
              v-if="
                collectionData.collection_suppliers &&
                collectionData.collection_suppliers.length > 0
              "
            >
              <v-row
                v-for="supplier in collectionData.collection_suppliers"
                v-bind:key="supplier.id"
              >
                <v-col cols="12" class="form-value">
                  <div
                    class="supplier-listing"
                    @click="openCompanyProfile(supplier.supplier.uuid)"
                  >
                    {{ supplier.supplier.name }}
                  </div>
                </v-col>
              </v-row>
            </div>
            <div v-else>
              <label class="form-value font-weight-bold">
                No suppliers added yet
              </label>
            </div>
          </div>
        </div>
      </md-content>
    </v-col>
  </v-card>
</template>

<script>
import moment from "moment";

export default {
  name: "AboutTab",
  props: {
    collectionData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    formatDate(date) {
      return moment(date).format("HH:mm Do MMMM YYYY");
    },
    openCompanyProfile(uuid) {
      window.open(`${window.location.origin}/#/company/${uuid}`, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.service_card_container {
  margin: 0px;
  margin-top: 25px;
  min-height: 400px;
  padding: 30px 0;
}
.subheading_color {
  background-color: $primaryDarkest;
  font-weight: 600;
  font-size: 16px;
}
.form-label {
  text-align: right;
  color: $tableBorder;
}
.form-value {
  text-align: left;
  color: $primaryDark;
  font-weight: 600;
}
.supplier-listing {
  text-align: center;
}
.supplier-listing:hover {
  cursor: pointer;
  text-decoration: underline;
}
</style>
